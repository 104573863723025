<template>
    <div>
        <v-text-field v-if="loadingProjectCategories" color="success" loading disabled></v-text-field>
        <ProjectForm v-else :projectCategories="projectCategories" :projectData="item"/>
    </div>
</template>
<script>
import ProjectForm from './ProjectForm';

import { mapState, mapActions } from 'vuex';

export default {
    props: {
        item: {
            type: Object
        },
    },
    created() {
        this.fetchDetail();
    },
    components: {
        ProjectForm,
    },
    computed: {
         ...mapState('author/dashboard', ['projectCategories', 'loadingProjectCategories'])
    },
    methods: {
        ...mapActions('author/dashboard', ['loadProjectCategories']),
        fetchDetail() {
            this.loadProjectCategories();
        }
    },   
}
</script>