<template>
    <div>
        <v-overlay :value="processingProject">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        {{message}}
        <v-form
          ref="obraform"
          v-model="valid"
          lazy-validation
          v-if="cat == 'profissional'">
          
          <v-card class="mt-8 pa-8 secondary">
            <!--v-card-text>
                <v-row class="mb-4" align="center">
                    <v-avatar color="grey" class="mr-4"></v-avatar>
                    <strong class="title">Obras Relacionadas</strong>
                    <div class="flex-grow-1"></div>
                    <v-btn icon>
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </v-row>
            </v-card-text-->

            <v-row>
              <v-col md="3">
                    <v-text-field dense
                        outlined
                        v-model="obra.name"
                        :rules="projnameRules"
                        :counter="60"
                        label="nome"
                        required
                    ></v-text-field>
                </v-col>

                <v-col md="3">
                    <v-textarea dense
                        outlined
                        :counter="450"
                        label="sinopse"
                        rows="1"
                        v-model="obra.sinopse"
                        />
                </v-col>

                <v-col md="2">
                  <v-file-input
                    placeholder="cartaz"
                    label="upload cartaz"
                    outlined
                    dense
                    :rules="file2MBrule"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    v-model="obra.cover"
                    accept="image/*"
                  ></v-file-input>
                </v-col>

                <v-col md="2">
                  <v-file-input
                    placeholder="banner"
                    label="upload banner"
                    outlined dense
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    v-model="obra.banner"
                    :rules="file2MBrule"
                    accept="image/*"
                  ></v-file-input>
                </v-col>

                <v-col md="2">
                    <v-file-input
                      dense
                      v-model="obra.obrafiles"
                      color="deep-purple accent-4"
                      counter
                      :rules="filerules"
                      label="upload fotos"
                      multiple
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="fotos"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      :show-size="1000"
                      max="4"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 1"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 1"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ obra.obrafiles.length - 1 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field dense
                        outlined
                        :counter="60"
                        :rules="externLinkRules"
                        v-model="obra.externLink"
                        label="link externo de referencia (opcional)"
                        required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                    <v-autocomplete dense
                        v-model="obra.category"
                        :items="projectCategories"
                        :rules="projcategoryRules"
                        filled
                        solo-inverted
                        flat
                        label="categoria do projecto/obra"
                        item-text="name"
                        item-value="id"
                    >
                        <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle>{{data.item.category}}/{{data.item.subcategory}}</v-list-item-subtitle>
                            </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>

            </v-row>
            <v-row>
                <v-col cols="12" md="6" >
                  
                  <!--v-date-picker  
                    v-model="obra.dataProducao" 
                    locale="pt-PT"></v-date-picker-->

                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="obra.dataProducao"
                        label="Data de Produção"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker locale="pt-PT"
                      v-model="obra.dataProducao"
                      @input="menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    dense
                    v-model="obra.paisProducao"
                    :items="countries"
                    filled
                    chips
                    outlined
                    height="10"
                    label="País de Produção"
                    item-text="text"
                    item-value="value"
                    prepend-inner-icon="mdi-map-marker"
                  >
                </v-autocomplete>
              </v-col>

                  <v-col v-if="error" cols="12" md="12" sm="12">
                    <v-alert type="error"><p class="white--text">{{error}}</p></v-alert>
                  </v-col>


                  <v-col v-if="message"  cols="12" md="12" sm="12">
                    <v-alert type="success" outlined dismissible><p class="white--text">{{message}}</p></v-alert>
                  </v-col>

              </v-row>
            <v-row>

                <v-col cols="12" md="12">
                  <label>Bio da obra*</label>
                  <div class="black-text">
                    <ckeditor
                        :editor="editor"
                        v-model="obra.bio"
                        :config="editorConfig"
                    ></ckeditor>
                  </div>
                </v-col>
            </v-row>
            <v-row>

                  <v-col cols="12" md="3">
                      <v-dialog v-model="creditDialog" max-width="600px">
                          <template v-slot:activator="{ on }">
                              <v-btn color="success" v-on="on">
                                <span class="white--text">Adiconar Creditos</span>
                              </v-btn>
                          </template>

                          <v-card>
                              <v-toolbar
                                  flat
                                  color="#FFCC00"
                                  dark
                              >
                                  <v-toolbar-title class="black--text">
                                      Creditos
                                  </v-toolbar-title>
                              </v-toolbar>

                              <!--Form starts here-->
                              <v-card-text>
                                  <v-container>
                                      <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                              <v-text-field dense
                                                  outlined
                                                  :counter="60"
                                                  label="creditos da obra"
                                                  required
                                                  v-model="credit"
                                                  @click:append="addCredit"
                                                  @keydown.enter="addCredit"
                                                  :append-icon="credit ? 'mdi-send' : ''"
                                              ></v-text-field>
                                          </v-col>

                                          <v-col
                                              cols="12"
                                              sm="12"
                                              md="12"
                                          >
                                              <v-data-table
                                                  :headers="creditHeaders"
                                                  :items="obra.credits"
                                                  :items-per-page="3"
                                                  class="elevation-1"
                                              >
                                                  <template v-slot:[`item.action`]="{ item }">
                                                      <v-icon
                                                          color="error"
                                                          small
                                                          @click="removeCredits(item)"
                                                      >
                                                          mdi-delete
                                                      </v-icon>
                                                  </template>
                                              </v-data-table>
                                          </v-col>
                                      </v-row>
                                  </v-container>
                              </v-card-text>

                              <v-card-actions>
                                  <v-btn color="blue darken-1" text @click="creditDialog = false">Sair</v-btn>
                              </v-card-actions>
                          </v-card>
                      </v-dialog>
                  </v-col>
                <v-col cols="12" md="3">
                    <v-dialog v-model="award_dialog" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success"  v-on="on">
                            <span class="white--text"> Premios/Awards</span>
                          </v-btn>
                        </template>

                        <v-card>
                            <v-toolbar
                                flat
                                color="#FFCC00"
                                dark
                            >
                                <v-toolbar-title class="black--text">
                                  Awards
                                </v-toolbar-title>
                            </v-toolbar>

                            <!--Form starts here-->
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field dense
                                                outlined
                                                :counter="60"
                                                label="premios/awards da obra"
                                                required
                                                v-model="award"
                                                @click:append="addAward"
                                                @keydown.enter="addAward"
                                                :append-icon="award ? 'mdi-send' : ''"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                        >
                                            <v-data-table
                                                :headers="headers"
                                                :items="obra.awards"
                                                :items-per-page="3"
                                                class="elevation-1"
                                            >
                                                <template v-slot:[`item.action`]="{ item }">
                                                    <v-icon
                                                        color="error"
                                                        small
                                                        @click="removeAwards(item)"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn color="blue darken-1" text @click="award_dialog = false">Sair</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                  </v-col>

            </v-row>
            
          </v-card>
        </v-form>

      <v-col cols="12">
          <v-btn 
            @click="addObra" 
            color="primary"
            :loading="processingProject" :disabled="processingProject"
            style="border-left-style: 12px solid;border-left-color: coral;">
            <span class="black--text"><v-icon dark color="black">mdi-plus</v-icon>
            <span v-if="obra.id" class="black--text">Atualizar</span>
            <span v-else class="black--text">Adicionar</span> Obra</span>
          </v-btn>

          <v-btn
            v-if="obra.id"
            @click="deleteObra" 
            class="ml-2"
            color="error"
            :loading="processingProject" :disabled="processingProject"
            style="border-left-style: 12px solid;border-left-color: coral;">
            <span class="black--text"><v-icon dark color="black">mdi-delete</v-icon>
            <span class="black--text">Eliminar Obra</span></span>
          </v-btn>
        </v-col>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getYoutubeVimeoExternalLinkValue } from '../../../utils';
import { mapState, mapActions } from 'vuex';
import {mask} from 'vue-the-mask';
import * as file from '../../../utils/file';
import _ from 'lodash';

export default {
  $_veeValidate: {
    validator: 'new',
  },
  directives: {
    mask
  },
  created() {
    if (this.projectData) {
      this.obra = _.merge(this.obra, this.projectData);
    }
    this.loadFilters();
  },
  props: {
    cat: {
      type: String,
      default: "profissional"
    },
    projectCategories: {
      type: Array,
      default: () => [],
    },
    projectsData: {
      type: Array,
      default: () => [],
    },
    projectData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menuDate: false,
      loading: false,
      address: null,
      error: null,
      message: null,
      profile: {},
      lastInsertID: null,
      /** */
      isLoading: false,

      personal_bio: null,
      professional_bio: null,
      // Obra Fields
      obra: {
        obrafiles: [],
        cover: null,
        banner: null,
        credits: [],
        sinopse: null,
        externLink: null,
        bio: null,
        category: null,
        name: null,
        awards: [],
        description: null,
        isPublic: true,
        dataProducao: null,
      },

      award: null,
      credit: null,
      valid: true,
      filerules: [
        value => !value || value.length <= 4 || 'So pode fazer upload de até quatro ficheiros tipo imagem',
        //value => !!this.obra.id || !value || value.length > 0 || '',
        file.factoryRule({  totalSize: { max: 10, unit: 'MB' } })
      ],
      file2MBrule: [
        file.factoryRule({  totalSize: { max: 2, unit: 'MB' } })
      ],
      
      sinopseRules: [
        v => !!v || 'Sinopse is required'
      ],
      projcategoryRules: [
        v => !!v || 'Category is required'
      ],
      projnameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 60) || 'Name must be less than 60 characters',
      ],
      award_dialog: false,
      creditDialog: false,

      externLinkRules: [
       v => !v || v.length <= 60 || 'Link externo must be less than or equal 60 characters',
        v => !v || getYoutubeVimeoExternalLinkValue(v) != null || 'Tem de ser um link de vimeo ou de youtube'
      ],

      //Obra Table
      addedObraHeaders: [
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],

      headers: [
          {
              text: 'Award',
              align: 'left',
              sortable: false,
              value: 'name',
          },
          { text: 'Remover', value: 'action', sortable: false },
      ],

      creditHeaders: [
          {
              text: 'Credito',
              align: 'left',
              sortable: false,
              value: 'name',
          },
          { text: 'Remover', value: 'action', sortable: false },
      ],

      //! Validation dictionary
      dictionary: {
        attributes: {
          username: 'Username',
          category: 'Sub-Category',
          city: 'Address',
          firstName: 'First Name',
          lastName: 'Last Name',

          phone_number: 'Phone Number',
          email: 'Email',
        },
        custom: {
          username: {
            required: () => 'username can not be empty',
            max: 'username field may not be greater than 30 characters'
          },
          category: {
            required: () => 'subcategory can not be empty'
          },
          city: {
            required: () => 'address can not be empty'
          },
          firstName: {
            required: () => 'first name can not be empty'
          },
          lastName: {
            required: () => 'last name can not be empty'
          },

          email: {
            required: () => 'email field can not be empty'
          },
          phone_number: {
            required: () => 'phone number can not be empty'
          }
        }
      },

      editor: ClassicEditor,
      editorConfig: {
          toolbar: [ 
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              //'imageUpload',
              'blockQuote',
              'undo',
              'redo',
              //'insertTable'
          ],
          heading: {
              options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
              ]
          }
      },
    }
  },
  computed: {
    readonly() {
      return false
    },
    ...mapState('author/dashboard', ['processingProject']),
    ...mapState('author/database', ['filters', 'loadingFilter']),
    countries () {
        return this.filters.countries || [];
    },
  },
  mounted() {
    this.$validator.localize('en', this.dictionary)
  },
  methods: {
    ...mapActions('author/database', {
        loadFilters: 'loadFilters',
    }),
    ...mapActions('author/dashboard', ['createOrUpdateProject', 'deleteProject']),
    
    /*async submit() {
      const valid = await this.$validator.validateAll()
      if (valid) {
        const payload = {
          obras: this.addedObra,
          isActive: false
        }

        await this.$store.dispatch('profile/create', payload)

        if (this.lastInsertID != null) {
          this.$router.push({ name: 'view-from-other-place', params: { profile: this.lastInsertID } })
        }
      }
    },*/

    addAward() {
      if (this.award) {
        this.obra.awards.push({name: this.award})
        this.award = null
      }
    },
    addCredit() {
      if (this.credit) {
        this.obra.credits.push({name: this.credit})
        this.credit = null
      }
    },

    addObra() {
      this.message = null;
      this.error = null;
      if (this.$refs.obraform.validate()) {
        
        
        this.createOrUpdateProject({data: this.obra, token: this.$user.info}).then((data) => {
          if (data.success) {
            this.message = data.message;
            if (data.isUpdate) {
              /*const i = this.addedObra.findIndex((o) => o.id === data.data.id);
              if ( i != -1) {
                this.$set(this.addedObra, i, data.data);
              }*/
            } else {
              //this.addedObra.unshift(data.data);
              this.clearObraFields();
            }
            
            
            //this.$refs.obraform.reset();
          } else {
            this.error = data.error;
          }
        });
        
      }
      
    },

    clearObraFields() {
      this.obra = {
        obrafiles: [],
        credits: [],
        banner: null,
        cover: null,
        description: "",
        sinopse: "",
        externLink: "",
        bio: "",
        category: "",
        name: "",
        awards: [],
        isPublic: true,
      }
    },

    /*editAddedObra(item) {
      Object.assign(this.obra, item)

      //! Store this in case any field change, remove from list and add the new one
      this.editedobra = {}
      Object.assign(this.editedobra, item)
    },*/

    /*removeAddedObra(item) {
      this.obraToDelete = item;
      this.editedobra = null
    },*/

    deleteObra() {
      this.message = null;
      this.error = null;
      this.deleteProject({data: this.obra, token: this.$user.info}).then((data) => {

          if (data.success) {
            this.message = data.message;
            this.clearObraFields();
            //this.addedObra = (this.addedObra || []).filter(o => o.id !== data.id);
          } else {
            this.error = data.error;
          }
        }).catch(
          e => this.error = e
        )
    },

    /*cancelDeleteObra() {
      this.obraToDelete = null;
    },*/

    removeAwards(item) {
      if (!confirm("Award selecionado será removido da lista.")) {
        return;
      }
      if (this.obra.awards === null || this.obra.awards === undefined) {
        return;
      }

      this.obra.awards = this.obra.awards.filter(i => {
        if (!i.id && !item.id) return i.name != item.name;
        return i.id !== item.id;
      });
    },

    removeCredits(item) {
      if (!confirm("Creditos selecionado será removido da lista.")) {
        return;
      }
      if (this.obra.credits === null || this.obra.credits === undefined) {
        return;
      }

      this.obra.credits = this.obra.credits.filter(i => {
        if (!i.id && !item.id) return i.name != item.name;
        return i.id !== item.id;
      });
    }
  }
}
</script>