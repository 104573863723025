<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline primary--text">Atualizar Projeto</v-subheader>

                <p-breadcrumbs page="profile.control-panel.project.edit" />
            </v-col>
        </v-row>
        <ProjectList v-if="project && project.id" :item="project" />
    </v-container>
</template>

<script>

import ProjectList from '../../../components/author/control-panel/ProjectList';
//import { mapState, mapActions } from 'vuex';
import {HOME} from '../../../routes';
import { mapState, mapActions } from 'vuex';
export default {
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    
    components: {
        ProjectList,
    },
    computed: {
        userId() {
            return this.$user.info.id;
        },
        projId() {
            return this.$route.query.id;
        },
         ...mapState('mySpace/project', ['project', 'loading'])
    },
    methods: {
        ...mapActions({
            detail: 'mySpace/project/detail',
        }),
        fetchDetail() {
            this.detail({ id: this.projId, forUpdate: true});
        }
    },   
}
</script>